$env: production;
@import '@/styles/_component-base.styles.scss';

.valueProps {
    &-transparent {
        background-color: transparent;
    }

    &-contrast-1 {
        background-color: color(contrast, 1);
    }

    .row {
        padding: spacer(xs) 8px;
        min-height: spacer(7xl);

        @include only-breakpoint(xs){
            margin: 0;
            width: 100%;
        }
    }
}

.valueProp {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: spacer(sm);

    max-width: 252px;
    padding: 0 spacer(2xs);
    margin: spacer(sm) 0;

    text-align: left;
    text-decoration: none;
    font-weight: $fontWeight-base;
    color: color(text, headingDark);

    @include only-breakpoint(xs){
        line-height: 1.4;
    }

    @include breakpoint(380px){
        justify-content: flex-end;
    }

    @include only-breakpoint(md){
        flex-direction: column-reverse;
        align-items: center;
    }

    @include breakpoint(lg){
        align-items: center;
        font-size: set-fontSize(18);
    }
}

.iconWrapper {
    width: 40px;
    height: 40px;
    margin: 0;

    display: flex;
    justify-content: center;

    @include breakpoint(md){
        width: 50px;
        height: 50px;
    }
}

.icon {
    width: 100%;
    color: color(accent, text);
}

.link {
    text-decoration: underline;
    color: color(text, headingDark);

    @include breakpoint(lg){
        text-decoration: none;
    }

    &:hover, &:focus {
        color: color(text, headingDark);
        text-decoration: underline;

        + .iconWrapper .icon {
            stroke: rgb(164, 164, 164);
        }
    }
}
